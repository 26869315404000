<script>
    import AbsenceItem from "./AbsenceItem.svelte";
    import Loading from "../../../components/Loading-new.svelte";
    import { post } from "../../../js/labit-connection";
    import { getMinutesFromAbsence } from "../../../js/formatDateTime";
    import { getUserId } from "../../../js/localInfo";
    import moment from "moment";

    let myId = getUserId();
    let absTime = {};

    const getHolidays = async () => {
        let res = await post(
            `SELECT g.name, g.startDate 
                FROM people AS p
                INNER JOIN companies AS c ON p.company_id = c.company_id
                INNER JOIN general_holidays AS g ON c.City = g.location
                WHERE p.contact_id = ${myId};`,
        );
        const now = moment();
        return res
            .map((e) => {
                return {
                    name: e.name,
                    date: moment(e.startDate, "YYYY-MM-DD"),
                };
            })
            .filter((e) => e.date.isSameOrAfter(now, "day"));
    };

    const getAbsences = async () => {
        const holidays = await getHolidays();

        let query = `select p.contact_id as user_id, a.id as absence_id, p.name as user, at.name as type, a.description, a.start, a.end, a.half, a.accepted_by_team_leader, a.accepted_by_ceo, a.requested_time, at.approvable 
            from people as p inner join absences as a on a.user_id = p.contact_id
            inner join absence_type as at on a.absence_type_id = at.id order by a.requested_time desc, start desc;`;

        let unformattedAbsences = await post(query);
        
        let res = [];

        [res, absTime] = unformattedAbsences.reduce(
            (acc, e) => {
                let accepted_by_team_leader = "",
                    accepted_by_ceo = "",
                    approvable = "";

                e.approvable !== "1"
                    ? ((accepted_by_team_leader = "-"),
                      (accepted_by_ceo = "-"),
                      (approvable = "No"))
                    : ((accepted_by_team_leader = yesOrNo(
                          e.accepted_by_team_leader,
                      )),
                      (accepted_by_ceo = yesOrNo(e.accepted_by_ceo)),
                      (approvable = "Yes"));

                acc[0] = [
                    ...acc[0],
                    {
                        id: e.absence_id,
                        user_id: e.user_id,
                        user: e.user,
                        type: e.type,
                        description: e.description,
                        start: e.start,
                        end: e.end,
                        half: e.half,
                        accepted_by_team_leader: accepted_by_team_leader,
                        accepted_by_ceo: accepted_by_ceo,
                        requested_time: e.requested_time,
                        approvable: approvable,
                    },
                ];

                if (e.approvable === "1" && e.accepted_by_ceo === "1") {
                    const start = moment(e.start, "x");
                    const end = moment(e.end, "x");
                    const diffMinutes = (e.end - e.start) / 1000 / 60;
                    const duration = getMinutesFromAbsence(
                        start,
                        end,
                        holidays,
                        diffMinutes,
                    );

                    if (acc[1].hasOwnProperty(e.user_id)) {
                        acc[1][e.user_id] = [...acc[1][e.user_id], duration];
                    } else {
                        acc[1] = { [e.user_id]: [duration] };
                    }
                }

                return acc;
            },
            [[], {}],
        );

        for (var key in absTime) {
            absTime[key] = absTime[key].reduce((acc, e) => acc + e, 0);
        }

        return res;
    };
    let promise = getAbsences();

    const handleClick = () => {
        promise = getAbsences();
    };

    const yesOrNo = (num) => {
        return num === "1" ? "Yes" : num === "-1" ? "No" : "-";
    };

    async function updateAbsenceStatus(absenceId, newState) {
        // Aquí, crea la consulta SQL para actualizar el estado de la ausencia
        let updateQuery = `UPDATE absences SET accepted_by_team_leader = '${newState}' WHERE id = ${absenceId};`;

        // Ejecuta la consulta
        await post(updateQuery);

        // Actualiza la lista local de ausencias sin recargar toda la lista
        absences = absences.map((absence) =>
            absence.id === absenceId
                ? { ...absence, accepted_by_team_leader: newState }
                : absence,
        );
    }

</script>

<div id="over-auto">
    {#await promise}
        <div class="loading-container">
            <Loading />
        </div>
    {:then absences}
        <table class="w-100" style="table-layout: fixed;">
            <tr class="table-header">
                <th>Requested on</th>
                <th>Name</th>
                <th>Type</th>
                <th>Approvable</th>
                <th>Start</th>
                <th>End</th>
                <th>Accepted by team leader</th>
                <th>Accepted by Elvira</th>
            </tr>
            {#each absences as absence}
                <AbsenceItem
                    {...absence}
                    on:action={({ detail: { action, id } }) =>
                        updateAbsenceStatus(id, action)}
                />
            {/each}
        </table>
    {:catch error}
        <p style="color: red">{error.message}</p>
    {/await}
</div>

<style>
    .loading-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #over-auto {
        overflow: auto;
        width: 100%;
        height: 100%;
    }

    tr,
    th {
        height: 40px;
        color: #6a6a6a;
    }

    .table-header {
        background-color: #eaeff5;
    }

    .w-100 {
        width: 100% !important;
    }
</style>
