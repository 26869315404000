<script>
  export let createPeople = false;
  export let createCompany = false;
  export let showPeopleId = -1;
  export let showCompanyId = -1;
  export let showMenu;
  export let refreshCompanyList = false;

  import TextEdit from "../components/TextEdit.svelte";
  import UserCard from "../components/UserCard.svelte";
  import CompanyCard from "../components/CompanyCard.svelte";
  import UserList from "../components/UserList.svelte";
  import CompanyList from "../components/CompanyList.svelte";
  import DialogUserProfile from "../components/DialogUserProfile.svelte";
  import DialogCompany from "../components/DialogCompany.svelte";
  import DialogRemove from "../components/DialogRemove.svelte";
  import DialogNewCategory from "../components/DialogNewCategory.svelte";
  import SubmenuBackgroundButton from "../components/SubmenuBackgroundButton.svelte";
  import SubmenuMultipleSelect from "../components/SubmenuMultipleSelect.svelte";
  import SelectableMenuButton from "../components/SelectableMenuButton.svelte";
  import dayjs from 'dayjs';
  import Loading from "../components/Loading.svelte";

  import userProfileEmpty from "../data/userProfileEmpty";
  import userProfileEmptyMin from "../data/userProfileEmptyMin";

  import { post, rpost, LABIT_PUBLIC_FOLDER } from "../js/labit-connection";
  import { getRole, getBackPage } from "../js/localInfo";
  import { removeAccents } from "../js/removeAccents";
  import { randomString } from "../js/randomString";
  import {
    createUserPicName,
    createCompanyPicName,
  } from "../js/createPicNames";
  import { copyObject } from "../js/copyObject";
  import { openURL } from "../js/openURL";

  import { onMount, onDestroy, beforeUpdate } from "svelte";

  import moment from 'moment';
  import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

  dayjs.extend(isSameOrAfter);

  let users = [];

  let companies = [];
  let companiesLabit = [];
  let companiesNoLabit = [];
  let selectedCompany = null;

  let showPeople = true;
  let showCompanies = true;

  let categories = [];
  let selectedCategory = [];
  let companiesOptions = [];
  let selectedCompanyOptions = [];
  let offices = [];
  let selectedOffice = [];
  let charges = [];
  let selectedCharge = [];
  let status = [
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Inactive",
      label: "Inactive",
    },
  ];
  let selectedStatus = [];
  let roles = [];
  let selectedRole = [];

  let searchFilter = "";

  let remainingCards = 0;
  let invisibleCards = 0;
  let remainingCardsLabit = 0;
  let invisibleCardsLabit = 0;
  let remainingCardsNoLabit = 0;
  let invisibleCardsNoLabit = 0;

  let visualizationMode = "cards"; // cards, list

  let selectedUser = copyObject(userProfileEmpty);
  let dialogAction = () => editProfile();
  let dialogInitialState = createPeople ? "edit" : "show"; // show, edit
  let showProfileDialog = createPeople;
  let showNewCategoryDialog = false;
  let showRemoveDialog = false;
  let removeElement = "";
  let removeType = ""; // people, company
  let showCompanyDialog = false;

  let cityCodes = [];

  let myRole = getRole();

  let savedCompany = null;

  let loading = true;

  const PEOPLE_REMOTE_URL = "/people";
  const COMPANIES_REMOTE_URL = "/companies";

  beforeUpdate(() => {
    if (createCompany) {
      selectedCompany = {
        id: -1,
        pic: "",
        name: "",
        //categoryId: -1,
        //category: "",
        categories: [],
        address: "",
        city: "",
        cityCode: "",
        country: "",
        telephone: "",
        email: "",
        labit: false,
        map: "",
        picPath: "",
        picImage: null,
        active: true,
        latitude: "",
        longitude: "",
        practise: "",
      };
    }
  });

  
  let getPeopleInterval;
  
  onDestroy(() => {
    clearInterval(getPeopleInterval);
  });

  onMount(async () => {
    await getCityCodes();
    await getCategories();
    await getCharges();
    await getRoles();
    await getUsers();
    await getCompanies();

    selectedCategory = categories.reduce((acc, curr) => {
      if (curr.label === "Team") {
        acc.push(curr.value);
      }
      return acc;
    }, []);

    selectedCompanyOptions = companiesOptions.reduce((acc, curr) => {
      if (curr.label === "Labit Group") {
        acc.push(curr.value);
      }
      return acc;
    }, []);

    selectedStatus = status.reduce((acc, curr) => {
      if (curr.label === "Active") {
        acc.push(curr.value);
      }
      return acc;
    }, []);

    showPeopleIdDialog();
    showCompanyIdDialog();

    loading = false;
    
    getPeopleInterval = setInterval( async () => {
      const element = document.getElementById('peopleCards');
      if (element.offsetParent !== null) {
        await getUsers();
      }
    }, 15000 );
  });


  let showPeopleIdDialog = () => {
    if (showPeopleId !== -1) {
      const pos = users.findIndex((user) => {
        return user.id === showPeopleId;
      });
      if (pos >= 0) {
        showProfile(true, pos);
      } else {
        showProfile(false, -1);
      }
      showProfileDialog = true;
      showPeopleId = -1;
    }
  };

  let showCompanyIdDialog = () => {
    if (showCompanyId !== -1) {
      let comp = companiesLabit.find((item) => {
        return item.id === showCompanyId;
      });
      if (!comp) {
        comp = companiesNoLabit.find((item) => {
          return item.id === showCompanyId;
        });
      }
      if (comp) {
        showCompany(true, comp);
      }
      showCompanyId = -1;
    }
  };

  let getCityCodes = async () => {
    let response = await post(
      `select name, code2, code3
        from city_codes
        order by name asc`
    );
    cityCodes = response.map((item) => {
      return {
        name: item.name,
        code2: item.code2,
        code3: item.code3,
      };
    });
  };

  let getCategories = async () => {
    const response = await post(
      `select category_id, Name 
        from categories
        order by sort asc`
    );
    categories = response.map((item) => {
      return {
        value: item.category_id,
        label: item.Name,
      };
    });
  };

  let getRoles = async () => {
    const response = await post(
      `select id, name 
      from userRoles
      order by id asc`
    );
    roles = response.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    selectedRole = [];
  };

  let getCompanies = async () => {
    let response = await post(
      `select id, company, category, Name
        from companyCategories as cc
        left join categories as c on c.category_id=cc.category`
    );
    const categories = response.map((item) => {
      return {
        id: item.id,
        company: item.company,
        category: item.category,
        categoryName: item.Name,
      };
    });

    response = await post(
      `select company_id, co.Name as companyName, Logo_url, Address, City, CityCode, Country, PhoneNumber, Email, ca.category_id as categoryId, ca.Name as categoryName, esLabit, gMapUrl, Active, picPath, latitude, longitude, code, practise
      from companies as co
      left join categories as ca on ca.category_id=co.category_id`
    );
    companiesLabit = [];
    companiesNoLabit = [];
    response.forEach((item) => {
      let pic =
        item.Logo_url && item.Logo_url !== ""
          ? item.Logo_url
          : "/images/default-company.png";
      let company = {
        id: item.company_id,
        code: item.code,
        pic,
        name: item.companyName ? item.companyName : "",
        //categoryId: item.categoryId,
        //category: item.categoryName,
        categories: [],
        address: item.Address,
        city: item.City ? item.City : "",
        cityCode: item.CityCode,
        country: item.Country,
        telephone: item.PhoneNumber,
        email: item.Email ? item.Email : "",
        labit: item.esLabit === "1",
        map: item.gMapUrl,
        active: item.Active === "1",
        picPath: item.picPath,
        latitude: item.latitude,
        longitude: item.longitude,
        practise: item.practise,
        visible: true,
      };

      company.categories = categories.reduce((acc, curr) => {
        if (curr.company === company.id) {
          acc.push({
            id: curr.category,
            name: curr.categoryName,
          });
        }
        return acc;
      }, []);

      if (company.labit) {
        companiesLabit.push(company);
      } else {
        companiesNoLabit.push(company);
      }

      const code = cityCodes.find((cityCode) => {
        return cityCode.name === company.city;
      });
      if (code) {
        company.cityCode = code.code2;
      } else {
        company.cityCode = "";
      }

      companies.push(company);
    });

    //console.log("COMPANIES", companies);

    companiesLabit.sort((a, b) => {
      let A = removeAccents(a.name);
      let B = removeAccents(b.name);
      A = A.toUpperCase();
      B = B.toUpperCase();
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });

    companiesNoLabit.sort((a, b) => {
      let A = removeAccents(a.name);
      let B = removeAccents(b.name);
      A = A.toUpperCase();
      B = B.toUpperCase();
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });

    companiesOptions = response.map((item) => {
      return {
        value: item.company_id,
        label: item.companyName,
      };
    });
    companiesOptions.sort((a, b) => {
      let A = removeAccents(a.label);
      let B = removeAccents(b.label);
      A = A.toUpperCase();
      B = B.toUpperCase();
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });

    remainingCardsLabit = companiesLabit.length % 4;
    invisibleCardsLabit = remainingCardsLabit > 0 ? 4 - remainingCardsLabit : 0;
    remainingCardsNoLabit = companiesNoLabit.length % 4;
    invisibleCardsNoLabit =
      remainingCardsNoLabit > 0 ? 4 - remainingCardsNoLabit : 0;

    applyFilter();
  };

  let togglePeople = () => {
    showPeople = !showPeople;
  };

  let toggleCompanies = () => {
    showCompanies = !showCompanies;
  };

  let getCharges = async () => {
    const response = await post(
      `select distinct Charge 
      from people
      order by Charge asc`
    );
    charges = response.reduce((acc, curr) => {
      if (curr.Charge !== null && curr.Charge !== "") {
        acc.push({
          value: curr.Charge,
          label: curr.Charge,
        });
      }
      return acc;
    }, []);
    selectedCharge = [];
  };

  let getUsers = async () => {
    offices = [];

    let response = await post(
      `select company, category, Name
        from companyCategories as cc
        left join categories as c on c.category_id=cc.category
        order by category asc`
    );

    const companyCategories = [];
    response.forEach((item) => {
      const pos = companyCategories.findIndex((c) => {
        return c.company === item.company;
      });
      if (pos === -1) {
        companyCategories.push({
          company: item.company,
          categoryId: item.category,
          categoryName: item.Name,
        });
      }
    });

    users = await post(
      `select *, p.Name as userName, p.PhoneNumber as phoneNumber1, c.Name as companyName, r.id as roleId, p.City as userCity, p.Email as userEmail, p.picPath as userPicPath, p.Country as pCountry, BirthDate, lastActivity, ps.description as profileStatusDescription, ps.iconImg as profileStatusIcon, atHome, zoom, slack
      from people as p 
      left join companies as c on p.company_id=c.company_id 
      left join roles as r on r.id=p.role
      left join profileStatus as ps on ps.id=p.profileStatus`
    );
    users = users.map((user) => {
      if (user.userCity) {
        const pos = offices.findIndex((item) => {
          return item.value === user.userCity.toUpperCase();
        });
        if (pos === -1) {
          offices.push({
            value: user.userCity.toUpperCase(),
            label: user.userCity.toUpperCase(),
          });
        }
      }
      return {
        id: user.contact_id,
        pic: user.PictureUrl,
        name: user.userName === null ? "" : user.userName,
        fullName: user.FullName === null ? "" : user.FullName,
        companyId: user.company_id === null ? -1 : user.company_id,
        company: user.companyName === null ? "" : user.companyName,
        companyRole: user.Charge === null ? "" : user.Charge,
        categoryId: "",
        categoryName: "",
        prof: user.role === null ? "" : user.role,
        profId: user.roleId === null ? -1 : user.roleId,
        profRole: user.ProfessionRole === null ? "" : user.ProfessionRole,
        email1: user.userEmail === null ? "" : user.userEmail,
        telephone1: user.phoneNumber1 === null ? "" : user.phoneNumber1,
        extension: user.Extension === null ? "" : user.Extension,
        email2: user.Email2 === null ? "" : user.Email2,
        telephone2: user.PhoneNumber2 === null ? "" : user.PhoneNumber2,
        location: user.userCity === null ? "" : user.userCity,
        country: user.pCountry === null ? "" : user.pCountry,
        status: user.active === "1",
        birthDate:
          user.BirthDate === null || user.BirthDate === ""
            ? "Unknown"
            : moment(user.BirthDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
        web: user.Web === null ? "" : user.Web,
        notes: user.Notes === null ? "" : user.Notes,
        nickName: user.Nickname === null ? "" : user.Nickname,
        userName: user.userName === null ? "" : user.Username,
        password: "",
        briefing: user.Briefing === null ? "" : user.Briefing,
        sentence: user.Sentence === null ? "" : user.Sentence,
        background: user.Background === null ? "" : user.Background,
        education: user.Education === null ? "" : user.Education,
        educationPlace: user.EducationPlace === null ? "" : user.EducationPlace,
        educationYear: user.EducationYear === null ? "" : user.EducationYear,
        skills: user.Skills === null ? "" : user.Skills,
        interest: user.Interest === null ? "" : user.Interest,
        previousExperience:
          user.previousExperience === null ? "" : user.previousExperience,
        languages: user.languages === null ? "" : user.languages,
        picId: user.picId,
        picPath: user.userPicPath,
        platformAccess: user.Access,
        roles: [],
        lastActivity: user.lastActivity,
        officeStatus: {
          description: user.profileStatusDescription,
          icon: user.profileStatusIcon,
        },
        atHome: user.atHome,
        zoom: user.zoom,
        slack: user.slack,
        visible: true,
      };
    });
    fixVacations();
    users.forEach((user) => {
      const cc = companyCategories.find((item) => {
        return item.company === user.companyId;
      });
      if (cc) {
        user.categoryId = cc.categoryId;
        user.categoryName = cc.categoryName;
      }
    });

    users.sort((a, b) => {
      let A = removeAccents(a.name);
      let B = removeAccents(b.name);
      A = A.toUpperCase();
      B = B.toUpperCase();
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });

    response = await post(
      `select distinct people
        from projectTeam
        where role="Project Manager"`
    );
    response.forEach((r) => {
      let user = users.find((item) => {
        return item.id === r.people;
      });
      if (user) {
        user.roles.push({
          id: 0,
          name: "Project Manager",
        });
      }
    });

    remainingCards = users.length % 4;
    invisibleCards = remainingCards > 0 ? 4 - remainingCards : 0;

    applyFilter();

    //console.log(users);
  };

  function changeVisualizationMode(mode) {
    visualizationMode = mode;
  }

  function showNewCategory(visible) {
    //showMenu = !visible;
    showNewCategoryDialog = visible;
  }

  async function showProfile(visible, index) {
    if (visible) {
      //showMenu = false;
      const user = users[index];
      //console.log(user);
      selectedUser = {
        id: user.id,
        card: {
          pic: user.pic,
          name: user.name,
          company: user.company,
          companyRole: user.companyRole,
          prof: user.prof,
          profId: user.profId,
          profRole: user.profRole,
          email1: user.email1,
          telephone1: user.telephone1,
          email2: user.email2,
          telephone2: user.telephone2,
          location: user.city,
        },
        profile: {
          fullName: user.fullName,
          nickName: user.nickName,
        },
        company: {
          id: user.companyId,
          categoryId: user.categoryId,
          officeCity: user.location,
          officeCountry: user.country,
          charge: user.companyRole,
          telephoneExtension: user.extension,
        },
        address: {
          address: "",
          city: "",
          postcode: "",
          country: "",
        },
        identities: {
          dni: "",
          ssn: "",
        },
        emergencies: {
          contact: "",
          telephone: "",
        },
        birthDate: user.birthDate,
        comments: "",
        user: {
          user: user.userName,
          password: user.password,
        },
        active: user.status,
        jobDates: {
          startDate: "",
          endDate: "",
        },
        timeTable: {
          hours: "0",
          unds: "Semana",
          weekDays: {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: false,
            sunday: false,
          },
        },
        laborContract: [],
        payroll: [],
        otherDocs: [],
        briefing: user.briefing,
        sentence: user.sentence,
        background: user.background,
        education: user.education,
        educationPlace: user.educationPlace,
        educationYear: user.educationYear,
        skills: user.skills,
        interest: user.interest,
        previousExperience: user.previousExperience,
        languages: user.languages,
        linkedin: user.web,
        platformAccess: user.platformAccess,
        picId: user.picId,
        picPath: user.picPath,
        picImage: null,
        lastActivity: user.lastActivity,
        officeStatus: copyObject(user.officeStatus),
        atHome: user.atHome,
        zoom: user.zoom,
        slack: user.slack,
      };
      dialogAction = () => editProfile();
      dialogInitialState = "show";
    } else {
      const url = getBackPage();
      if (url !== "") {
        openURL(url, true);
      }
      showMenu = true;
      showNewCategoryDialog = false;
    }
    showProfileDialog = visible;
    createPeople = false;
  }

  function showRemove(visible, type) {
    if (visible) {
      //showMenu = false;
      removeElement =
        type === "people"
          ? `"User: ${selectedUser.card.name}"`
          : `"Company: ${selectedCompany.name}"`;
      removeType = type;
    }
    showRemoveDialog = visible;
  }

  async function showCompany(visible, company = null) {
    if (visible) {
      //showMenu = false;
      selectedCompany = JSON.parse(JSON.stringify(company));
      savedCompany = JSON.parse(JSON.stringify(company));
      dialogAction = () => editCompany();
      dialogInitialState = "show";
    } else {
      const url = getBackPage();
      if (url !== "") {
        openURL(url, true);
      }
      await getUsers();
      await getCompanies();
      if (!showRemoveDialog) {
        //showMenu = true;
      }
      showNewCategoryDialog = false;
    }
    showCompanyDialog = visible;
    createCompany = false;
  }

  let editCompany = async () => {
    let picUrl = "";
    let picPath = "";

    if (selectedCompany.picImage) {
      const companyPicName =
        createCompanyPicName(selectedCompany) +
        "." +
        selectedCompany.picImage.extension;
      picPath = COMPANIES_REMOTE_URL + "/" + companyPicName;
      picUrl = LABIT_PUBLIC_FOLDER + picPath;

      await rpost("UploadPublicFile", {
        path: COMPANIES_REMOTE_URL,
        fileName: companyPicName,
        fileData: selectedCompany.picImage.data,
      });
    }

    const response = await post(
      `update companies set
        Name='${selectedCompany.name}', 
        Code='${selectedCompany.code}', 
        Logo_url='${picUrl}',
        category_id=0,
        City='${selectedCompany.city}', 
        CityCode='${selectedCompany.cityCode}', 
        Country='${selectedCompany.country}',
        Address='${selectedCompany.address}', 
        PhoneNumber='${selectedCompany.telephone}', 
        Email='${selectedCompany.email}',
        esLabit='${selectedCompany.labit ? "1" : "0"}',
        gMapUrl='${selectedCompany.map}',
        picPath='${picPath}',
        Active='${selectedCompany.active ? "1" : "0"}',
        latitude='${selectedCompany.latitude}',
        longitude='${selectedCompany.longitude}',
        practise='${selectedCompany.practise}'
        where company_id=${selectedCompany.id}`
    );

    // Added Categories
    for (let i = 0; i < selectedCompany.categories.length; i++) {
      const cat = selectedCompany.categories[i];
      const pos = savedCompany.categories.findIndex((item) => {
        return item.id === cat.id;
      });
      if (pos === -1) {
        await post(
          `insert into companyCategories (company, category)
            values (${selectedCompany.id}, ${cat.id})`
        );
      }
    }

    // Removed Categories
    for (let i = 0; i < savedCompany.categories.length; i++) {
      const cat = savedCompany.categories[i];
      const pos = selectedCompany.categories.findIndex((item) => {
        return item.id === cat.id;
      });
      if (pos === -1) {
        await post(
          `delete from companyCategories
            where company=${savedCompany.id} and category=${cat.id}`
        );
      }
    }

    selectedCompany.pic =
      picUrl === "" ? picUrl : picUrl + "?" + randomString(10);
    selectedCompany.picPath = picPath;
    selectedCompany = selectedCompany;

    await getUsers();
    await getCompanies();
  };

  let newCompany = async () => {
    createCompany = false;

    let picUrl = "";
    let picPath = "";

    let response = await post(
      `insert into companies
        (Name,
          Logo_url,
          City,
          CityCode,
          Country,
          Address,
          PhoneNumber,
          Email,
          esLabit,
          gMapUrl,
          picPath,
          Active,
          latitude,
          longitude,
          code,
          practise
        ) values (
          '${selectedCompany.name}', 
          '',
          '${selectedCompany.city}', 
          '${selectedCompany.cityCode}', 
          '${selectedCompany.country}',
          '${selectedCompany.address}', 
          '${selectedCompany.telephone}', 
          '${selectedCompany.email}',
          '${selectedCompany.labit ? "1" : "0"}',
          '${selectedCompany.map}',
          '',
          '${selectedCompany.active ? "1" : "0"}',
          '${selectedCompany.latitude}',
          '${selectedCompany.longitude}',
          '${selectedCompany.code}',
          '${selectedCompany.practise}'
        )`
    );

    selectedCompany.id = response[0];

    if (selectedCompany.picImage) {
      const companyPicName =
        createCompanyPicName(selectedCompany) +
        "." +
        selectedCompany.picImage.extension;
      picPath = COMPANIES_REMOTE_URL + "/" + companyPicName;
      picUrl = LABIT_PUBLIC_FOLDER + picPath;

      await rpost("UploadPublicFile", {
        path: COMPANIES_REMOTE_URL,
        fileName: companyPicName,
        fileData: selectedCompany.picImage.data,
      });

      await post(
        `update companies
          set 
          Logo_url='${picUrl}',
          picPath='${picPath}'
          where company_id=${selectedCompany.id}`
      );
    }

    selectedCompany.pic = picUrl + "?" + randomString(10);
    selectedCompany.picPath = picPath;
    selectedCompany = selectedCompany;

    for (let i = 0; i < selectedCompany.categories.length; i++) {
      const cat = selectedCompany.categories[i];
      await post(
        `insert into companyCategories (company, category)
        values (${selectedCompany.id}, ${cat.id})`
      );
    }

    //showMenu = true;
    showCompanyDialog = false;
    showNewCategoryDialog = false;
    dialogInitialState = "show";
    dialogAction = () => editCompany();

    await getUsers();
    await getCompanies();
  };

  async function editProfile() {
    let user = null;
    let oldUserName = "";
    if (selectedUser.id === -1) {
      user = userProfileEmptyMin;
    } else {
      user = users.find((item) => {
        return item.id === selectedUser.id;
      });
      oldUserName = user.name;
    }

    let zoom = "";
    if (selectedUser.zoom !== "") {
      zoom = selectedUser.zoom.startsWith("http")
        ? selectedUser.zoom
        : "https://" + selectedUser.zoom;
    }

    user.name = selectedUser.card.name;
    user.fullName = selectedUser.profile.fullName;
    //user.pic = selectedUser.card.pic;
    user.company = selectedUser.card.company;
    user.companyId = selectedUser.company.id;
    user.categoryId = selectedUser.company.categoryId;
    user.location = selectedUser.company.officeCity;
    user.country = selectedUser.company.officeCountry;
    user.companyRole = selectedUser.card.companyRole;
    user.email1 = selectedUser.card.email1;
    user.telephone1 = selectedUser.card.telephone1;
    user.extension = selectedUser.company.telephoneExtension;
    user.email2 = selectedUser.card.email2;
    user.telephone2 = selectedUser.card.telephone2;
    user.prof = selectedUser.card.prof;
    user.profId = selectedUser.card.profId;
    user.profRole = selectedUser.card.profRole;
    user.briefing = selectedUser.briefing;
    user.sentence = selectedUser.sentence;
    user.background = selectedUser.background;
    user.interest = selectedUser.interest;
    user.previousExperience = selectedUser.previousExperience;
    user.languages = selectedUser.languages;
    user.education = selectedUser.education;
    user.educationPlace = selectedUser.educationPlace;
    user.educationYear = selectedUser.educationYear;
    user.skills = selectedUser.skills;
    user.web = selectedUser.linkedin;
    user.status = selectedUser.active;
    user.nickName = selectedUser.profile.nickName;
    user.userName = selectedUser.user.user;
    user.password = selectedUser.user.password;
    user.platformAccess = selectedUser.platformAccess;
    user.birthDate = selectedUser.birthDate;
    user.zoom = zoom;
    user.slack = selectedUser.slack;
    if (selectedUser.id === -1) {
      user.picId = randomString(32);
      let picUrl = "";
      let picPath = "";

      if (selectedUser.picImage) {
        const userPicName =
          createUserPicName(user) + "." + selectedUser.picImage.extension;
        picPath = PEOPLE_REMOTE_URL + "/" + userPicName;
        picUrl = LABIT_PUBLIC_FOLDER + picPath;

        await rpost("UploadPublicFile", {
          path: PEOPLE_REMOTE_URL,
          fileName: userPicName,
          fileData: selectedUser.picImage.data,
        });
      }

      user.pic = picUrl + "?" + randomString(10);
      user.picPath = picPath;

      selectedUser.card.pic = user.pic;
      selectedUser.picId = user.picId;
      selectedUser.picPath = user.picPath;

      user = user;
      selectedUser = selectedUser;

      const token = randomString(32);

      const response = await post(
        `insert into people (
          Name,
          FullName,
          PhoneNumber,
          Extension,
          PhoneNumber2,
          Email,
          Email2,
          Charge,
          Web,
          City,
          Country,
          company_id,
          PictureUrl,
          Nickname,
          ProfessionRole,
          Briefing,
          Sentence,
          Background,
          Education,
          EducationPlace,
          EducationYear,
          Skills,
          Interest,
          previousExperience,
          languages,
          active,
          role,
          Username,
          _Password_,
          Access,
          picId,
          picPath,
          birthDate,
          zoom,
          slack,
          token
          ) VALUES (
            '${user.name}',
            '${user.fullName}',
            '${user.telephone1}',
            '${user.extension}',
            '${user.telephone2}',
            '${user.email1}',
            '${user.email2}',
            '${user.companyRole}',
            '${user.web}',
            '${user.location}',
            '${user.country}',
            ${user.companyId},
            '${picUrl}',
            '${user.nickName}',
            '${user.profRole}',
            '${user.briefing}',
            '${user.sentence}',
            '${user.background}',
            '${user.education}',
            '${user.educationPlace}',
            '${user.educationYear}',
            '${user.skills}',
            '${user.interest}',
            '${user.previousExperience}',
            '${user.languages}',
            ${user.status ? "1" : "0"},
            null,
            '${user.userName}',
            '',
            '${user.platformAccess}',
            '${user.picId}',
            '${user.picPath}',
            '${
              user.birthDate === "Unknown" || user.company !== "Labit Group"
                ? ""
                : moment(user.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            }',
            '${user.zoom}',
            '${user.slack}',
            '${token}'
          )`
      );

      const id = response[0];

      if (user.password !== "") {
        await rpost("SavePassword", { contact_id: id, pass: user.password });
      }
    } else {
      let picUrl = "";
      let picPath = "";

      if (selectedUser.picImage) {
        const userPicName =
          createUserPicName(user) + "." + selectedUser.picImage.extension;
        picPath = PEOPLE_REMOTE_URL + "/" + userPicName;
        picUrl = LABIT_PUBLIC_FOLDER + picPath;

        await rpost("UploadPublicFile", {
          path: PEOPLE_REMOTE_URL,
          fileName: userPicName,
          fileData: selectedUser.picImage.data,
        });
      }

      user.pic = picUrl + "?" + randomString(10);
      user.picPath = picPath;

      selectedUser.card.pic = user.pic;
      selectedUser.picPath = user.picPath;

      user = user;
      selectedUser = selectedUser;

      await post(
        `update people set 
          Name='${user.name}', 
          FullName='${user.fullName}',
          PhoneNumber='${user.telephone1}',
          Extension='${user.extension}',
          PhoneNumber2='${user.telephone2}',
          Email='${user.email1}',
          Email2='${user.email2}',
          Charge='${user.companyRole}',
          Web='${user.web}',
          City='${user.location}',
          Country='${user.country}',
          company_id=${user.companyId},
          PictureUrl='${picUrl}',
          Nickname='${user.nickName}',
          ProfessionRole='${user.profRole}',
          Briefing='${user.briefing}',
          Sentence='${user.sentence}',
          Background='${user.background}',
          Education='${user.education}',
          EducationPlace='${user.educationPlace}',
          EducationYear='${user.educationYear}',
          Skills='${user.skills}',
          Interest='${user.interest}',
          previousExperience='${user.previousExperience}',
          languages='${user.languages}',
          active=${user.status ? "1" : "0"},
          role=null,
          Username='${user.userName}',
          Access='${user.platformAccess}',
          picPath='${user.picPath}',
          BirthDate='${
            user.birthDate === "Unknown" || user.company !== "Labit Group"
              ? ""
              : moment(user.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          }',
          zoom='${user.zoom}',
          slack='${user.slack}'
          where contact_id=${user.id}`
      );

      await rpost("SavePassword", {
        contact_id: user.id,
        pass: user.password,
      });

      if (oldUserName !== user.name) {
        const oldPath = "/people/" + user.id + " - " + oldUserName;
        const newPath = "/people/" + user.id + " - " + user.name;
        await rpost("RenameFolder", {
          oldPath,
          newPath,
        });
      }
    }

    createPeople = false;

    await getUsers();
    await getCompanies();
  }

  let removeProfile = async () => {
    await post(
      `delete from people 
      where contact_id=${selectedUser.id}`
    );
    await getUsers();
    await getCompanies();

    showRemove(false, "");
    showProfile(false);
  };

  let removeCompany = async () => {
    await post(
      `delete from companies
        where company_id=${selectedCompany.id}`
    );

    await getUsers();
    await getCompanies();

    showRemove(false, "");
    showCompany(false);
  };

  let clearFilters = () => {
    selectedCategory = [];
    selectedCompanyOptions = [];
    selectedOffice = [];
    selectedCharge = [];
    selectedRole = [];
    selectedStatus = [];
    searchFilter = "";
  };

  let applyFilter = () => {
    users.forEach((item) => {
      item.visible = true;
    });
    companiesLabit.forEach((item) => {
      item.visible = true;
    });
    companiesNoLabit.forEach((item) => {
      item.visible = true;
    });

    if (searchFilter !== "") {
      const SearchFilter = searchFilter.toUpperCase();
      users.forEach((item) => {
        if (item.visible) {
          if (
            !item.fullName.toUpperCase().includes(SearchFilter) &&
            !item.name.toUpperCase().includes(SearchFilter) &&
            !item.email1.toUpperCase().includes(SearchFilter) &&
            !item.email2.toUpperCase().includes(SearchFilter)
          ) {
            item.visible = false;
          }
        }
      });
      companiesLabit.forEach((item) => {
        if (item.visible) {
          if (
            !item.name.toUpperCase().includes(SearchFilter) &&
            !item.email.toUpperCase().includes(SearchFilter)
          ) {
            item.visible = false;
          }
        }
      });
      companiesNoLabit.forEach((item) => {
        if (item.visible) {
          if (
            !item.name.toUpperCase().includes(SearchFilter) &&
            !item.email.toUpperCase().includes(SearchFilter)
          ) {
            item.visible = false;
          }
        }
      });
    } //else {
    // Category

    if (selectedCategory && selectedCategory.length > 0) {
      users.forEach((item) => {
        if (item.visible) {
          const pos = selectedCategory.findIndex((c) => {
            return c === item.categoryId;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
      companiesLabit.forEach((item) => {
        if (item.visible) {
          let found = false;
          for (let i = 0; i < selectedCategory.length && !found; i++) {
            const c = selectedCategory[i];
            const pos = item.categories.findIndex((cat) => {
              return cat.id === c;
            });
            found = pos !== -1;
          }
          if (!found) {
            item.visible = false;
          }
        }
      });
      companiesNoLabit.forEach((item) => {
        if (item.visible) {
          let found = false;
          for (let i = 0; i < selectedCategory.length && !found; i++) {
            const c = selectedCategory[i];
            const pos = item.categories.findIndex((cat) => {
              return cat.id === c;
            });
            found = pos !== -1;
          }
          if (!found) {
            item.visible = false;
          }
        }
      });
      /*companiesLabit.forEach((item) => {
          if (item.visible) {
            const pos = selectedCategory.findIndex((c) => {
              return c === item.categoryId;
            });
            if (pos === -1) {
              item.visible = false;
            }
          }
        });
        companiesNoLabit.forEach((item) => {
          if (item.visible) {
            const pos = selectedCategory.findIndex((c) => {
              return c === item.categoryId;
            });
            if (pos === -1) {
              item.visible = false;
            }
          }
        });*/
    }

    // Company

    if (selectedCompanyOptions && selectedCompanyOptions.length > 0) {
      users.forEach((item) => {
        if (item.visible) {
          const pos = selectedCompanyOptions.findIndex((c) => {
            return c === item.companyId;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
      companiesLabit.forEach((item) => {
        if (item.visible) {
          const pos = selectedCompanyOptions.findIndex((c) => {
            return c === item.id;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
      companiesNoLabit.forEach((item) => {
        if (item.visible) {
          if (item.id !== selectedCompanyOptions.value) {
            const pos = selectedCompanyOptions.findIndex((c) => {
              return c === item.id;
            });
            if (pos === -1) {
              item.visible = false;
            }
          }
        }
      });
    }

    // Office

    if (selectedOffice && selectedOffice.length) {
      users.forEach((item) => {
        if (item.visible) {
          const pos = selectedOffice.findIndex((c) => {
            return c === item.location.trim().toUpperCase();
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
      companiesLabit.forEach((item) => {
        if (item.visible) {
          const pos = selectedOffice.findIndex((c) => {
            return c === item.city.trim().toUpperCase();
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
      companiesNoLabit.forEach((item) => {
        if (item.visible) {
          const pos = selectedOffice.findIndex((c) => {
            return c === item.city.trim().toUpperCase();
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }

    // Charge

    if (selectedCharge && selectedCharge.length > 0) {
      users.forEach((item) => {
        if (item.visible) {
          const pos = selectedCharge.findIndex((c) => {
            return c === item.companyRole;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
      companiesLabit.forEach((item) => {
        item.visible = false;
      });
      companiesNoLabit.forEach((item) => {
        item.visible = false;
      });
    }

    // Role

    if (selectedRole && selectedRole.length > 0) {
      users.forEach((item) => {
        if (item.visible) {
          const pos = selectedRole.findIndex((c) => {
            return c === item.platformAccess;
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
      companiesLabit.forEach((item) => {
        item.visible = false;
      });
      companiesNoLabit.forEach((item) => {
        item.visible = false;
      });
    }

    // Status

    if (selectedStatus && selectedStatus.length > 0) {
      users.forEach((item) => {
        if (item.visible) {
          const pos = selectedStatus.findIndex((c) => {
            return (
              (c === "Active" && item.status) ||
              (c === "Inactive" && !item.status)
            );
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
      companiesLabit.forEach((item) => {
        if (item.visible) {
          const pos = selectedStatus.findIndex((c) => {
            return (
              (c === "Active" && item.active) ||
              (c === "Inactive" && !item.active)
            );
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
      companiesNoLabit.forEach((item) => {
        if (item.visible) {
          const pos = selectedStatus.findIndex((c) => {
            return (
              (c === "Active" && item.active) ||
              (c === "Inactive" && !item.active)
            );
          });
          if (pos === -1) {
            item.visible = false;
          }
        }
      });
    }
    //}

    const numUsers = users.reduce((acc, curr) => {
      if (curr.visible) {
        acc++;
      }
      return acc;
    }, 0);
    const numLabitCompanies = companiesLabit.reduce((acc, curr) => {
      if (curr.visible) {
        acc++;
      }
      return acc;
    }, 0);
    const numNoLabitCompanies = companiesNoLabit.reduce((acc, curr) => {
      if (curr.visible) {
        acc++;
      }
      return acc;
    }, 0);
    remainingCards = numUsers % 4;
    invisibleCards = remainingCards > 0 ? 4 - remainingCards : 0;
    remainingCardsLabit = numLabitCompanies % 4;
    invisibleCardsLabit = remainingCardsLabit > 0 ? 4 - remainingCardsLabit : 0;
    remainingCardsNoLabit = numNoLabitCompanies % 4;
    invisibleCardsNoLabit =
      remainingCardsNoLabit > 0 ? 4 - remainingCardsNoLabit : 0;

    users = users;
    companiesLabit = companiesLabit;
    companiesNoLabit = companiesNoLabit;
    //console.log(users);
  };

  let prepareCreatePeople = () => {
    dialogInitialState = "edit";
    selectedUser = copyObject(userProfileEmpty);
    showProfileDialog = true;
  };

  $: if (
    selectedCategory ||
    selectedCompanyOptions ||
    selectedOffice ||
    selectedCharge ||
    selectedRole ||
    selectedStatus ||
    searchFilter
  ) {
    applyFilter();
  }

  $: if (createPeople) {
    prepareCreatePeople();
  }

  $: if (users.length > 0 && showPeopleId !== -1) {
    showPeopleIdDialog();
  }

  $: if (
    (companiesLabit.length > 0 || companiesNoLabit.length > 0) &&
    showCompanyId !== -1
  ) {
    showCompanyIdDialog();
  }

  $: if (refreshCompanyList === true) {
    getCompanies();
    refreshCompanyList = false;
  }

  async function fixVacations(){
    const today = dayjs();
    const nextAbsencesLimit = dayjs().add(1, 'week');
    const absencesPromise = await post('SELECT a.start, a.end, a.accepted_by_ceo, a.half, a.user_id, at.approvable FROM absences as a JOIN absence_type as at ON a.absence_type_id = at.id;');
    let absences = absencesPromise
            .map(absence => {
                const { start, end, accepted_by_ceo, half, user_id, approvable } = absence;
                return { id: user_id , start: dayjs(parseInt(start)), end: dayjs(parseInt(end)), accepted: parseInt(accepted_by_ceo), half: parseInt(half), approvable: parseInt(approvable)}
            })
            .filter(absence => absence.end.isSameOrAfter(today, 'day') && absence.start.isBefore(nextAbsencesLimit,'day') && (absence.accepted === 1 || absence.approvable === 0) );
    const final = absences.filter((absence) => today.isBetween(absence.start, absence.end, 'day', '[]'))
    final.forEach( (absence) => 
      users.forEach( (user) => {
        user.officeStatus.icon = (absence.id === user.id) ? "/images/profile-status/holidays.svg" : user.officeStatus.icon;
        user.officeStatus.description = (absence.id === user.id)? "On Holidays" : user.officeStatus.description;
      } )
    );
  };
  
</script>

{#if loading}
  <Loading size="50px" marginTop="20px" />
{/if}

{#if showProfileDialog}
  <DialogUserProfile
    bind:userProfile={selectedUser}
    action={dialogInitialState}
    onClose={() => showProfile(false)}
    onSave={dialogAction}
    onRemove={() => {
      showRemove(true, "people");
      showProfile(false);
    }}
    newProfile={createPeople}
    bind:showMenu
    bind:refreshCompanyList
  />
{:else if createCompany}
  <DialogCompany
    company={selectedCompany}
    action={"new"}
    onClose={() => showCompany(false)}
    onSave={() => newCompany()}
    onRemove={null}
    newCompany={createCompany}
  />
{:else if showCompanyDialog}
  <DialogCompany
    company={selectedCompany}
    action={dialogInitialState}
    onClose={() => showCompany(false)}
    onSave={dialogAction}
    onRemove={() => {
      showRemove(true, "company");
      showCompany(false);
    }}
  />
{:else if showNewCategoryDialog}
  <DialogNewCategory onClose={() => showNewCategory(false)} />
{:else if showRemoveDialog}
  <DialogRemove
    element={removeElement}
    onClose={() => {
      showRemove(false, "");
      showProfile(false);
    }}
    onRemove={() => {
      if (removeType === "people") {
        removeProfile();
      } else {
        removeCompany();
      }
    }}
  />
{/if}
<div
  class="content {showPeopleId !== -1 ||
  showCompanyId !== -1 ||
  showProfileDialog ||
  createCompany ||
  showCompanyDialog ||
  showNewCategoryDialog ||
  showRemoveDialog
    ? 'hiddenElement'
    : ''}"
>
  <!-- Menu Options -->

  {#if !loading}
    <div class="optionsMenu">
      <div class="peopleOptions">
        <SelectableMenuButton
          selectedImage="/images/people-white.svg"
          noSelectedImage="/images/people.svg"
          selected={showPeople}
          onClick={() => togglePeople()}
        />
        <SelectableMenuButton
          selectedImage="/images/companies-white.svg"
          noSelectedImage="/images/companies.svg"
          selected={showCompanies}
          onClick={() => toggleCompanies()}
        />
      </div>
      <div class="filterOptions">
        <div class="optionsColumn1Top">
          <div class="optionsSearch">
            <span>Search</span>
            <TextEdit
              placeholder="type here..."
              maxlength="150"
              img="/images/search.svg"
              bind:value={searchFilter}
            />
          </div>
          
          <div class="addButtons">
            <SubmenuBackgroundButton
              text="New Contact"
              img="/images/people.svg"
              imgHover="/images/people-white.svg"
              onClick={() => {
                openURL("/people/create-people", true);
              }}
            />
            {#if myRole === "Admin"}
              <SubmenuBackgroundButton
                text="New Company"
                img="/images/companies.svg"
                imgHover="/images/companies-white.svg"
                onClick={() => {
                  openURL("/people/create-company", true);
                }}
              />
            {/if}
          </div>
        </div>
        <div class="optionsColumn1Bottom">
          <SubmenuMultipleSelect
            text="Category"
            options={categories}
            bind:selected={selectedCategory}
            width="165px"
          />
          <SubmenuMultipleSelect
            text="Company"
            options={companiesOptions}
            bind:selected={selectedCompanyOptions}
            width="165px"
          />
          <SubmenuMultipleSelect
            text="Office"
            options={offices}
            bind:selected={selectedOffice}
            width="165px"
          />
          <SubmenuMultipleSelect
            text="Charge"
            options={charges}
            bind:selected={selectedCharge}
            width="165px"
          />
          <SubmenuMultipleSelect
            text="Role"
            options={roles}
            bind:selected={selectedRole}
            width="165px"
          />
          <SubmenuMultipleSelect
            text=""
            options={status}
            bind:selected={selectedStatus}
            width="165px"
          />
        </div>
      </div>
      <div class="buttonOptions">
        <div class="optionsIcons">
          <img
            src="/images/list_square.svg"
            alt="cards"
            on:click={() => changeVisualizationMode("cards")}
          />
          <img
            src="/images/list_column.svg"
            alt="list"
            on:click={() => changeVisualizationMode("list")}
          />
        </div>
        <div class="optionsColumn2">
          <SubmenuBackgroundButton
            text="Clear all"
            img="/images/x.svg"
            imgHover="/images/x-white.svg"
            onClick={() => clearFilters()}
          />
        </div>
      </div>
    </div>

    <!-- Body -->

    <div
      class="companySectionCards {visualizationMode === 'cards' && showCompanies
        ? ''
        : 'hiddenElement'}"
    >
      {#each companiesLabit as company}
        {#if company.visible}
          <div class="companySectionCard">
            <CompanyCard
              pic={company.pic}
              name={company.name}
              role={company.email}
              categories={company.categories}
              city={company.address}
              country={company.city + " " + company.cityCode}
              telephone={company.telephone}
              labit={true}
              active={company.active}
              onClick={() => showCompany(true, company)}
            />
          </div>
        {/if}
      {/each}
      {#each Array(invisibleCardsLabit) as _, i}
        <div class="companySectionCard" />
      {/each}
      {#each companiesNoLabit as company}
        {#if company.visible}
          <div class="companySectionCard">
            <CompanyCard
              pic={company.pic}
              name={company.name}
              role={company.email}
              categories={company.categories}
              city={company.address}
              country={company.city + " " + company.cityCode}
              telephone={company.telephone}
              labit={false}
              active={company.active}
              onClick={() => showCompany(true, company)}
            />
          </div>
        {/if}
      {/each}
      {#each Array(invisibleCardsNoLabit) as _, i}
        <div class="companySectionCard" />
      {/each}
    </div>
    <div id="peopleCards"
      class="userSectionCards {visualizationMode === 'cards' && showPeople
        ? ''
        : 'hiddenElement'}"
    >
      {#each users as user, index}
        {#if user.visible}
          <div class="userSectionCard">
            <UserCard
              pic={user.pic}
              name={user.name}
              company={user.company}
              companyRole={user.companyRole}
              profRole={user.profRole}
              email1={user.email1}
              telephone1={user.telephone1}
              email2={user.email2}
              telephone2={user.telephone2}
              zoom={user.zoom}
              slack={user.slack}
              active={user.status}
              lastActivity={user.lastActivity}
              officeStatus={user.officeStatus}
              atHome={user.atHome}
              onClick={() => showProfile(true, index)}
            />
          </div>
        {/if}
      {/each}
      {#each Array(invisibleCards) as _, i}
        <div class="userSectionCard" />
      {/each}
    </div>
    <div
      class="userSectionList {visualizationMode === 'list' && showCompanies
        ? ''
        : 'hiddenElement'}"
    >
      {#each companiesLabit as company, index}
        {#if company.visible}
          <CompanyList
            pic={company.pic}
            name={company.name}
            category={company.code}
            address={company.address}
            city={company.city}
            status={company.active}
            email={company.email}
            onClick={() => showCompany(true, company)}
          />
        {/if}
      {/each}
      {#each companiesNoLabit as company, index}
        {#if company.visible}
          <CompanyList
            pic={company.pic}
            name={company.name}
            category={company.code}
            address={company.address}
            city={company.city}
            status={company.active}
            email={company.email}
            onClick={() => showCompany(true, company)}
          />
        {/if}
      {/each}
    </div>
    <div
      class="userSectionList {visualizationMode === 'list' && showPeople
        ? ''
        : 'hiddenElement'}"
    >
      {#each users as user, index}
        {#if user.visible}
          <UserList
            pic={user.pic}
            name={user.name}
            company={user.company}
            companyRole={user.companyRole}
            location={user.location}
            status={user.status}
            email1={user.email1}
            onClick={() => showProfile(true, index)}
          />
        {/if}
      {/each}
    </div>
  {/if}
</div>

<style>
  div.content {
    width: 1640px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100%;
  }

  div.optionsMenu {
    width: 100%;
    display: flex;
    margin-top: 20px;
    height: 55px;
    color: var(--labit-card-gray);
  }

  div.optionsColumn1Top {
    display: flex;
    flex-grow: 1;
    padding-right: 13px;
    justify-content: space-between;
  }

  div.optionsColumn1Bottom {
    display: flex;
    flex-grow: 1;
    padding-right: 13px;
    padding-left: 13px;
    justify-content: space-between;
    align-items: flex-end;
  }

  div.optionsColumn2 {
    display: flex;
  }

  div.optionsIcons {
    display: flex;
    width: 121px;
    height: 21px;
    justify-content: flex-end;
  }

  div.optionsIcons img {
    width: 21px;
    height: 21px;
    margin-left: 17px;
    cursor: pointer;
  }

  div.optionsSearch {
    display: flex;
    flex-grow: 1;
    height: 21px;
    align-items: center;
    padding-right: 13px;
    padding-left: 13px;
  }

  div.optionsSearch span {
    margin-right: 13px;
  }

  div.optionsMenu span {
    font-size: 13px;
  }

  div.userSectionCards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: flex-start;
    justify-content: space-between;
  }

  div.userSectionCard {
    height: 202px;
    width: 398px;
    margin-bottom: 19px;
  }

  div.userSectionList {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  div.companySectionCards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: flex-start;
    justify-content: space-between;
  }

  div.companySectionCard {
    height: 202px;
    width: 398px;
    margin-bottom: 19px;
  }

  div.peopleOptions {
    width: 122px;
    display: flex;
    justify-content: space-between;
  }

  div.filterOptions {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  div.buttonOptions {
    width: 122px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  div.addButtons {
    display: flex;
    width: 250px;
    justify-content: space-between;
  }

  /* Animations */

  div.optionsIcons img {
    transition: opacity 0.5s linear 0s;
  }

  div.optionsIcons img:hover {
    opacity: 0.5;
  }
</style>
