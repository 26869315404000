import { setOpenURL, setOpenURLUserAgent, getPlatform } from "./localInfo";
import { SLACK_FILE_SERVER } from "./globalExternalURLs";

// Detectar si estás en un entorno de Electron
const isElectron = typeof window !== "undefined" && window.process?.type === "renderer"; // Verificar si estamos en Electron
let shell = null;

if (isElectron) {
  try {
    shell = require("electron").shell; // Intentar importar shell desde Electron
  } catch (e) {
    console.error("Error al cargar el módulo shell de Electron:", e);
  }
}

const platform = getPlatform();

export let openNewWindow = (url, useragent = "") => {
  try {
    console.log("URL solicitada:", url);

    const isExternalLink = url.startsWith("http") || url.startsWith("/");
    const openUrl = isExternalLink ? url : `https://${url}`;

    if (platform === "Web") {
      // Para aplicaciones web estándar
      console.log("Abriendo en navegador (web):", openUrl);
      window.open(openUrl, "_blank");
    } else if (isElectron && shell) {
      // Para aplicaciones Electron
      console.log("Abriendo en navegador (Electron):", openUrl);
      if (url.startsWith(SLACK_FILE_SERVER)) {
        shell.openExternal(openUrl); // Manejo especial para Slack File Server
      } else {
        setOpenURL(openUrl);
        setOpenURLUserAgent(useragent);
        shell.openExternal(openUrl); // Abre en el navegador externo
      }
    } else {
      // Manejo de fallback genérico
      console.log("Abriendo con manejo genérico:", openUrl);
      setOpenURL(openUrl);
      setOpenURLUserAgent(useragent);
      window.open(openUrl, "_blank");
    }
  } catch (error) {
    console.error("Error al abrir la URL:", error);
  }
};
