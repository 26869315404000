<script>
  import { getContext } from "svelte";
  import { post } from "../../../js/labit-connection";
  import { countNonWorkingHours } from "../../../js/formatDateTime";

  import Calendar from "../../me/factorial/Calendar.svelte";
  import DropdownSelector from "./DropdownSelector.svelte";
  import AcceptAbsencesModal from "./AcceptAbsencesModal.svelte";
  import moment from "moment";
  import { tooltip } from "../../../components/tooltip/tooltip";

  const { open } = getContext("simple-modal");

  export let userId = 0;

  let [users, holidays, absenceTypesObj] = [{}, {}, {}];
  let [absenceTypesArr, holidaysArr, events] = [[], [], []];
  let [nRequested, generatedDays, daysUsed, available] = [0, 0, 0, 0];
  let [generatedThisYear, generatedLastYear] = [0, 0];
  let eventsObj = { accepted: [], holidaysUsed: [], requested: [], denied: [] };

  let extra = 0;
  let selectedUser;
  let usersSelector;
  let maxHolidays = 0;
  let hired;

  (async () => {
    const usersPromise = post(
      `SELECT contact_id AS id, name, city, hired, maxHolidays, extraHolidays FROM people WHERE active = 1 AND company_id = 2 ORDER BY name`
    );
    const absencesPromise = post(
      "SELECT id, name, color, approvable FROM absence_type;"
    );
    const generalHolidaysPromise = post(
      `SELECT name, startDate as date, location FROM general_holidays;`
    );

    let usersArr = [];
    [usersArr, absenceTypesArr, holidaysArr] = await Promise.all([
      usersPromise,
      absencesPromise,
      generalHolidaysPromise,
    ]);

    users = usersArr.reduce((acc, user) => {
      acc[user.id] = {
        name: user.name,
        city: user.city,
        hired: user.hired,
        maxHolidays: parseInt(user.maxHolidays),
        extraHolidays:
          user.extraHolidays === null ? 0 : parseInt(user.extraHolidays),
      };
      return acc;
    }, {});

    absenceTypesObj = absenceTypesArr.reduce((acc, abs) => {
      acc[abs.id] = {
        name: abs.name,
        color: abs.color,
        approvable: abs.approvable,
      };
      return acc;
    }, {});

    holidays = holidaysArr.reduce((acc, day) => {
      const locat = day.location;
      if (!acc.hasOwnProperty(locat)) acc[locat] = [];
      acc[locat].push({ name: day.name, date: moment(day.date, "YYYY-MM-DD") });
      return acc;
    }, {});

    usersSelector = usersArr.map((user) => {
      return { id: user.id, value: user.name };
    });

    if (userId !== 0) onSelectedUser(userId);
  })();

   const getGeneratedDays = (start, end) => {

     if (!moment.isMoment(start) || !moment.isMoment(end)) {
    console.error("start o end no son instancias de Moment.js");
    return 0; // o manejar el error de alguna otra manera
    }
    const freeTimeConstant = maxHolidays / 12;
    const days = end.diff(start, "days");
    console.log("DAYS",days);
    const monthDaysConstant = moment().isLeapYear() ? 366 / 12 : 365 / 12;
    const freeDays = (days / monthDaysConstant) * freeTimeConstant;
    console.log("FREEDAYSSS", freeDays);
    return freeDays.toFixed();
  };


  const getAbsences = async (id, location) => {
    const res = await post(
      `SELECT a.id, at.id as absenceType, at.name, at.color, at.color_light, a.description, a.start, a.end, a.half, a.accepted_by_team_leader, a.accepted_by_ceo, at.approvable FROM absences AS a JOIN absence_type AS at ON at.id = a.absence_type_id where a.user_id = ${id};`
    );
    events = [];
    const today = moment();
    let absences = res
      //.filter( (e) => ((e.approvable === "0") || (e.approvable === "1" && e.accepted_by_ceo === "1")) )
      .map((e) => {
        //const visible = ((e.approvable === "0") || (e.approvable === "1" && e.accepted_by_ceo === "1"));
        return {
          id: e.id,
          absenceType: e.absenceType,
          name: e.name,
          color: e.color,
          colorLight: e.color_light,
          description: e.description,
          start: new Date(parseInt(e.start)),
          end: new Date(parseInt(e.end)),
          half: e.half,
          acceptedByTL: e.accepted_by_team_leader,
          acceptedByCEO: e.accepted_by_ceo,
          editable: true,
          visible: true,
        };
      });

    let h;
    if (holidays.hasOwnProperty(location)) {
      h = holidays[location].map((e) => {
        const date = e.date.toDate();
        return {
          name: e.name,
          colorLight: "#F45B5B",
          description: "General holidays",
          start: date,
          end: date,
          editable: false,
          visible: true,
        };
      });
    }

    absences = h.concat(absences);
    events = absences.concat({
      name: "today",
      description: "Actual day",
      start: moment().toDate(),
      end: moment().toDate(),
      color: "#000000",
      colorLight: "#000000",
      editable: false,
      visible: true,
    });

    holidaysArr = holidaysArr
      .filter((day) => day.location === location)
      .map((day) => {
        return { date: moment(day.date, "YYYY-MM-DD") };
      });

    const filteredAbsences = res.filter((absence) => absence.absenceType !== "21"); 

    const durationCalculation = (start, end, halfDay) => {
      // Convertimos las fechas de inicio y fin a un formato que solo tenga en cuenta el día
      const startDate = start.startOf("day");
      const endDate = end.startOf("day");

      if (halfDay === "1") {
        return 0.5;
      }

      // Verificamos si las fechas de inicio y fin son el mismo día
      if (startDate.isSame(endDate)) {
        // Si son el mismo día, la duración es 1
        return 1;
      } else {
        // Si no son el mismo día, calculamos la duración como antes
        const end2 = moment(end).add(1, "day");
        return (
          end2.diff(start, "hours") / 24 -
          countNonWorkingHours(start, end2, holidaysArr, "all") / 8
        );
      }
    };
    

    eventsObj = { accepted: [], holidaysUsed: [], requested: [], denied: [] };
    eventsObj = filteredAbsences.reduce((acc, e) => {
      const start = moment(e.start, "x");
      const end = moment(e.end, "x");
      const now = moment();
      const duration = durationCalculation(start, end, e.half);


      const event = {
        id: e.id,
        absenceType: e.absenceType,
        start: start,
        end: end,
        description: e.description,
        duration: duration,
        half: e.half,
      };

      if (e.approvable === "1") {
        if (
          (e.accepted_by_ceo === "1" && e.accepted_by_team_leader === "1") ||
          e.accepted_by_ceo === "1"
        ) {
          // Ausencias aceptables aceptadas
          if (now.isAfter(end)) acc["holidaysUsed"].push(event);
          else acc["accepted"].push(event);
        } else if (
          e.accepted_by_ceo === "-1" ||
          e.accepted_by_team_leader === "-1"
        ) {
          acc["denied"].push(event);
        } else if (e.accepted_by_ceo === "0") {
          if (now.isAfter(end)) acc["denied"].push(event);
          else acc["requested"].push(event);
        }
      }

      return acc;
    }, eventsObj);

    let start;
    let year;
    const thisYearStart = moment().startOf("year");
    const pastYearStart = moment().subtract(1, "year").startOf("year");
    const pastYearEnd = moment().subtract(1, "year").endOf("year");
    const pastYearStart23 = moment().subtract(2, "year").startOf("year");

    const START_VACATION_YEAR = 2022;
    const hiredMoment = moment(hired, "YYYY-MM-DD HH:mm:ss");
    const hiredYear = hiredMoment.year();
    console.log("hired YEAR",hiredYear);
    console.log("hired MOMENT",hiredMoment);

     // Si la fecha de contratación es del año actual, no se generan días en el año anterior
    if (hiredMoment.isSameOrAfter(thisYearStart)) {
      start = hiredMoment;
    } else {
      if (hiredYear < START_VACATION_YEAR) {
        // Caso 2: Contratación antes de START_VACATION_YEAR
        start = moment([START_VACATION_YEAR]);
      } else if (hiredYear === START_VACATION_YEAR || hiredYear === pastYearStart.year() || hiredYear === pastYearStart23.year()) {
        // Casos 3 y 4: Contratación en 2022 o en el año anterior (2023)
       start = hiredMoment;
      } else {
        // Otros casos
      start = pastYearStart;
      }
    }

    if(hiredMoment.isSameOrAfter(pastYearStart) || hiredMoment.isSameOrAfter(pastYearStart23) || hiredYear === thisYearStart.year()){
      year = hiredMoment;
    } else {
      year = pastYearStart23
    }
    
    const generatedAllTime = parseInt(getGeneratedDays(start, today));
    const generatedYear = parseInt(getGeneratedDays(thisYearStart, today));
    const generatedPastYear = parseInt(getGeneratedDays(year,pastYearEnd));
    console.log("GENERATED YEARRRRR",generatedYear);
    console.log("GENERATED ALL TIME",generatedAllTime);
    console.log("GENERATED PAST YEAR",generatedPastYear);

    // Calcular los días de vacaciones utilizados en el año anterior
    const thisYear = moment().year();

    const nHolidaysUsed2022 = eventsObj["holidaysUsed"].reduce(
      (acc, e) =>
      acc +
        (e.absenceType === "1" && e.start.year() === thisYear - 3 // -4 para pasar a ser 2022
          ? e.duration
          : 0),
      0,
    );
    console.log("nHOLIDAYSUSED2022", nHolidaysUsed2022);
    
    // Calcular los días de vacaciones utilizados en el año anterior (2023)
    const nHolidaysUsed2023 = eventsObj["holidaysUsed"].reduce(
      (acc, e) =>
      acc +
        (e.absenceType === "1" && e.start.year() === thisYear - 2 // -3 para pasar a ser 2023
          ? e.duration
          : 0),
      0,
    );
    console.log("nHOLIDAYSUSED2023", nHolidaysUsed2023);

    // Calcular los días de vacaciones utilizados en 2024
    const nHolidaysUsed2024 = eventsObj["holidaysUsed"].reduce(  
      (acc, e) =>
      acc +
        (e.absenceType === "1" && e.start.year() === thisYear -1 // -2 para pasar a ser 2024
        ? e.duration : 0),
        0,
    );
    nRequested = eventsObj["requested"].reduce((acc, e) => acc + e.duration, 0)
    console.log("nHOLIDAYSUSED2024", nHolidaysUsed2024);

     // Calcular los días de vacaciones utilizados en 2025
     const nHolidaysUsed = eventsObj["holidaysUsed"].reduce( // cambiar el nombre a nHolidaysUsed2025
      (acc, e) =>
      acc +
        (e.absenceType === "1" && e.start.year() === thisYear // -1 para pasar a ser 2025
        ? e.duration : 0),
        0,
    );
    nRequested = eventsObj["requested"].reduce((acc, e) => acc + e.duration, 0)
    console.log("nHOLIDAYSUSEDthisYear", nHolidaysUsed);

    // Calcular los días de vacaciones utilizados en 2026
    /* const nHolidaysUsed = eventsObj["holidaysUsed"].reduce(
      (acc, e) =>
      acc +
        (e.absenceType === "1" && e.start.year() === thisYear ? e.duration : 0),
        0,
    );
    nRequested = eventsObj["requested"].reduce((acc, e) => acc + e.duration, 0)
    console.log("nHOLIDAYSUSEDthisYear", nHolidaysUsed); */
    
    // Actualizar los días generados totales con los días del año pasado y descontar los días utilizados
    generatedDays = generatedAllTime - nHolidaysUsed2024 - nHolidaysUsed2023 - nHolidaysUsed2022 + extra;  // Actualizar con nHolidaysUsed2025
    generatedLastYear = generatedAllTime - nHolidaysUsed2024 - nHolidaysUsed2023 - nHolidaysUsed2022 + extra - generatedYear; // Actualizar con nHolidaysUsed2025
    daysUsed = nHolidaysUsed
    available = generatedDays - daysUsed
    console.log("GENERATEDDAYS", generatedDays);
  };

  const openAbsenceModal = (e) => {
    if (e.detail.events.length !== 0) {
      const absence = e.detail.events[0];
      const absenceType = absenceTypesObj[absence.absenceType];
      open(
        AcceptAbsencesModal,
        { absence: absence, absenceType: absenceType },
        {},
        { onClosed: () => getAbsences(selectedUser.id, selectedUser.city) }
      );
    }
  };

  const isANumber = (n) => typeof n === "number" && !isNaN(n);
  const onSelectedUser = async (ev) => {
    const id = ev.detail || ev;
    selectedUser = users[id];
    maxHolidays = users[id].maxHolidays;
    hired = users[id].hired;
    selectedUser["id"] = id;
    extra = isANumber(parseInt(selectedUser.extraHolidays))
      ? parseInt(selectedUser.extraHolidays)
      : 0;
    await getAbsences(id, selectedUser.city);
    console.log("extraaaaaaaaa",extra);
  };
</script>

<div id="factorialContainer" class="flex">
  <div id="calendarContainer" class="flex flex-col">
    {#key events}
      <Calendar
        {events}
        absenceTypes={absenceTypesArr}
        on:dayClicked={openAbsenceModal}
      />
    {/key}
  </div>
  <div id="menu" class="flex flex-col">
    <div class="dropdown-container">
      <DropdownSelector
        options={usersSelector}
        defaultText={users.hasOwnProperty(userId)
          ? users[userId].name
          : "Select user"}
        on:selected={onSelectedUser}
      />
    </div>
    <div id="downloadAndCountersContainer">
      <div id="counters" class="flex">
        <div class="daysContainer flex flex-col">
          <!-- <span class="days">{generatedDays}</span> -->
          <!-- <img use:tooltip={{ x: -92, y: -52 }} src={src} alt="" width={size} description={tooltipText}> -->
          <span style="cursor: pointer;" class="days" use:tooltip={{ x: -92, y: -52 }} description={`Generated days: ${generatedThisYear} | Past year days: ${generatedLastYear}`}>{generatedDays}</span>
          <span class="tag">Generated</span>
        </div>
        <div class="daysContainer flex flex-col">
          <span class="days">{available}</span>
          <span class="tag">Available</span>
        </div>
        <div class="daysContainer flex flex-col">
          <span class="days">{daysUsed}</span>
          <span class="tag">Used</span>
        </div>
        <div class="daysContainer flex flex-col">
          <span class="days">{nRequested}</span>
          <span class="tag">Requested</span>
        </div>
      </div>
    </div>
    <div id="absencesContainer">
      <!-- eventsObj -->
      {#if eventsObj["requested"].length > 0}
        <div>
          <span class="absenceTitle">Requested absences</span>
          {#each eventsObj["requested"] as { id, absenceType, start, end, description, duration, half }}
            <div
              class="absence"
              on:click={() => {
                openAbsenceModal(
                  {
                    detail: {
                      id: id,
                      absenceType: absenceType,
                      description: description,
                      start: start,
                      end: end,
                      half: half,
                    },
                  },
                  "edit"
                );
              }}
            >
              <span class="width-200"
                >{start.format("DD MMM")} - {end.format("DD MMM")}
              </span>
              <span>{duration} days</span>
              <img src="/images/new/arrow-right-light.svg" alt="More Options" />
            </div>
          {/each}
        </div>
      {/if}
      {#if eventsObj["accepted"].length > 0}
        <div>
          <span class="absenceTitle">Accepted absences</span>
          {#each eventsObj["accepted"] as { start, end, duration }}
            <div class="absence" style="cursor: auto">
              <span class="width-200"
                >{start.format("DD MMM")} - {end.format("DD MMM")}
              </span>
              <span>{duration} days</span>
            </div>
          {/each}
        </div>
      {/if}
      {#if eventsObj["denied"].length > 0}
        <div>
          <span class="absenceTitle">Denied absences</span>
          {#each eventsObj["denied"] as { id, absenceType, start, end, description, duration, half }}
            <div
              class="absence"
              on:click={() => {
                openAbsenceModal(
                  {
                    detail: {
                      id: id,
                      absenceType: absenceType,
                      description: description,
                      start: start,
                      end: end,
                      half: half,
                    },
                  },
                  "edit"
                );
              }}
            >
              <span class="width-200"
                >{start.format("DD MMM")} - {end.format("DD MMM")}
              </span>
              <span>{duration} days</span>
            </div>
          {/each}
        </div>
      {/if}
      {#if eventsObj["holidaysUsed"].length > 0}
        <div>
          <span class="absenceTitle">Past absences</span>
          {#each eventsObj["holidaysUsed"] as { id, absenceType, start, end, description, duration, half }}
            <div
              class="absence"
              on:click={() => {
                openAbsenceModal(
                  {
                    detail: {
                      id: id,
                      absenceType: absenceType,
                      description: description,
                      start: start,
                      end: end,
                      half: half,
                    },
                  },
                  "edit"
                );
              }}
            >
              <span class="width-200"
                >{start.format("DD MMM")} - {end.format("DD MMM")}
              </span>
              <span>{duration} days</span>
            </div>
          {/each}
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  .dropdown-container {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .tag {
    font-weight: normal;
    color: #818181;
  }

  .flex {
    display: flex;
  }

  .flex-col {
    flex-direction: column;
  }

  #factorialContainer {
    flex-direction: row;
    width: 100%;
    background-color: white;
    height: 100%;
    border-radius: 0 0 19px 19px;
    color: #35425b !important;
  }

  #menu {
    width: 30%;
    height: 100%;
    padding: 20px;
  }

  #menu > * {
    margin: 20px 0;
  }

  div#absencesContainer {
    width: 100%;
    max-height: 540px;
    overflow: auto;
    display: flex;
    padding: 0 24px;
    flex-direction: column;
    gap: 12px;
  }

  div#absencesContainer > div {
    padding: 16px;
    border: 1px solid #ebebeb;
    border-radius: 20px;
  }

  div.absence {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0;
    align-items: baseline;
  }

  div.absence > img {
    height: 16px;
  }

  div.absence > * {
    flex-grow: 1;
  }

  span.absenceTitle {
    padding: 0 0 16px 0;
    display: block;
    font-size: 20px;
  }

  span.width-200 {
    width: 200px;
  }

  #calendarContainer {
    padding: 20px;
    width: 70%;
    height: 100%;
  }

  #counters {
    justify-content: space-between;
  }

  div#downloadAndCountersContainer {
    padding: 0 32px;
  }

  div#downloadAndCountersContainer > div {
    padding: 8px 0;
  }

  .daysContainer {
    text-align: center;
  }

  .daysContainer > .days {
    font-size: 40px;
    height: 38px;
  }
</style>
