<script>
	export let id;
	export let user;
	export let type;
	export let descriptionTooltip = false;
	export let start;
	export let end;
	export let half;
	export let accepted_by_team_leader;
	export let accepted_by_ceo;
	export let requested_time;
	export let approvable;
	export let position; //odd or even
	export let absTime = 0;

	import { post } from "../../../js/labit-connection";
	import { createEventDispatcher, onMount } from "svelte";
	import { absencesNotification } from '../../../js/stores'

	import moment from "moment";

	const dispatch = createEventDispatcher();

	function dispatchBtnClick() {
		dispatch("dispatchedClick");
	}

	let bgColor = position % 2 === 0 ? "#d7e1ea3d" : "#fff";

	$: if (accepted_by_ceo === "Yes") {
		bgColor = "rgb(82 232 87 / 13%)";
	} else if (accepted_by_ceo === "No") {
		bgColor = "rgb(232 82 82 / 13%)";
	} else if ( accepted_by_team_leader === "No"){
		bgColor = "rgb(232 82 82 / 13%)"
	} else {
		bgColor = position % 2 === 0 ? "#d7e1ea3d" : "#fff";
	}

	let halfTooltip = "";

	start = moment(start, "x");
	end = moment(end, "x");
	requested_time =
		requested_time === ""
			? ""
			: moment(requested_time, "x").format("DD/MM/YYYY HH:mm");

	if (start.isSame(end, "day")) {
		if (half !== "0") {
			halfTooltip = half === "1" ? "First half" : "Second half";
		} else {
			//halfTooltip = moment.duration(end.diff(start), "ms").format("hh:mm");
			halfTooltip = `${start.format("HH:mm")} to ${end.format("HH:mm")}`;
		}
	}

	if (end.diff(start, "days") === 1) {
		halfTooltip = "All the day";
		end.subtract(1, "ms");
	}

	let absenceResponse = async (id, accepted) => {
		let query = `update absences set accepted_by_ceo = "${accepted}" where id = ${id};`;
		await post(query);

		accepted_by_ceo = accepted === "1" ? "Yes" : "No";
		checkPendingAbsencesAndUpdateNotification();
	};

	let absenceResponseByTL = async (id, accepted) => {
		let query = `UPDATE absences SET accepted_by_team_leader = "${accepted}" WHERE id = ${id}`
		await post(query)

		accepted_by_team_leader = accepted === '1' ? "Yes" : "No";
		checkPendingAbsencesAndUpdateNotification();
	}

	async function checkPendingAbsencesAndUpdateNotification() {
    // Asumiendo que tu función post puede ejecutar la consulta SQL y devolver los resultados
    const query = "SELECT COUNT(*) AS pending_absences  FROM absences  WHERE accepted_by_ceo = 0 AND (absence_type_id = 1 OR absence_type_id = 21)";
    const result = await post(query);
	console.log("RESULTTTT",result)

    // Asumiendo que `result` contiene un array con el resultado de la consulta
    if (result[0].pending_absences === "0") {
        absencesNotification.set(false);
    }else{
		absencesNotification.set(true);
	}
	}

	/* // ----------------------------- USER ID --------------------------------- //

	let getUserID = async (id) => {
		try {
			let query = `select user_id from absences where id = ${id};`;
			let result = await post(query);
			// Suponiendo que el resultado es un array y contiene `user_id`
			return result[0]?.user_id || null;
		} catch (error) {
			console.error("Error al obtener user_id:", error);
			return null;
		}
	};

	// ---------------------- USER, ABSENCES, HOLIDAYS ----------------------- //

	let getUserAbsencesHolidays = async (id) => {
	
		try {
	    	
			let userPromise = await post(`SELECT contact_id, name, city, hired, extraHolidays, maxHolidays FROM people WHERE contact_id = ${id}`);
	    	let absencesPromise = await post("SELECT id, name, color, approvable FROM absence_type;");
	    	let generalHolidaysPromise = await post("SELECT name, startDate as date, location FROM general_holidays;");

	    	[userArr, absenceTypesArr, holidaysArr] = [userPromise, absencesPromise, generalHolidaysPromise];

	    	// Suponiendo que userArr devuelve un solo registro
	    	let rawUser = userArr[0];
	    	let userObj = {
	    	  name: rawUser.name,
	    	  city: rawUser.city,
	    	  hired: rawUser.hired,
	    	  maxHolidays: parseInt(rawUser.maxHolidays),
	    	  extraHolidays: rawUser.extraHolidays === null ? 0 : parseInt(rawUser.extraHolidays)
	    	};

	    	let absenceTypesObj = absenceTypesArr.reduce((acc, abs) => {
	    	  acc[abs.id] = {
	    	    name: abs.name,
	    	    color: abs.color,
	    	    approvable: abs.approvable,
	    	  };
	    	  return acc;
	    	}, {});

	    	let holidaysObj = holidaysArr.reduce((acc, day) => {
	    	  const locat = day.location;
	    	  if (!acc.hasOwnProperty(locat)) acc[locat] = [];
	    	  acc[locat].push({ name: day.name, date: moment(day.date, "YYYY-MM-DD") });
	    	  return acc;
	    	}, {});

	    	return {
	    	  user: userObj,
	    	  absencesTypes: absenceTypesObj,
	    	  holidays: holidaysObj
	    	};

	  	} catch (error) {
	  	  console.error("Error al obtener las ausencias disponibles", error);
	  	  return null;
	  	}
	};

	const getGeneratedDays = (start, end) => {

		const freeTimeConstant = maxHolidays / 12;
		const days = end.diff(start, "days");
		console.log("DAYS",days);
		const monthDaysConstant = moment().isLeapYear() ? 366 / 12 : 365 / 12;
		const freeDays = (days / monthDaysConstant) * freeTimeConstant;
		console.log("FREEDAYSSS", freeDays);
		return freeDays.toFixed();
	};

	onMount(async () => {
		if (id) {
			uId = await getUserID(id);
			user = await getUserAbsencesHolidays(uId);
			maxHolidays = user?.user?.maxHolidays || 0; // Ensure maxHolidays is updated
			freeD = getGeneratedDays(moment().startOf("year"), moment());
			console.log(freeD, "FREEE DAYSSSS ");
		}
	}); */


</script>

<tr class="" style="background-color:{bgColor}">
	
	<td>{requested_time}</td>
	
	<div data-tooltip={23 - Number((absTime / 60 / 8).toFixed(3)) + " days left"}>
		<td class="center">{user}</td>
	</div>
	
	<td>
		<span data-tooltip={descriptionTooltip}>{type}</span>
	</td>
	
	<td>{approvable}</td>
	
	<td>{start.format("DD/MM/YYYY")}</td>
	<!-- <td>{end.format("DD/MM/YYYY")}</td> -->
	<td>
		<div class="flex-row">
			<span>{end.format("DD/MM/YYYY")}</span>
			<!-- {#if halfTooltip !== ""}
				<div data-tooltip={halfTooltip}><div class="info"></div></div>
			{/if} -->
		</div>
	</td>
	
	<td>
		<div style="margin:auto" class="accepTl">

			{#if id !== -1 && approvable !== "No"}
			<label for="yes-{id}"><input type="radio" id="yes-{id}" name="absencesByTL-{id}" value="Yes" on:click={()=> absenceResponseByTL(id,"1")} checked={accepted_by_team_leader === 'Yes'}>Yes</label>
			<label for="no-{id}"><input type="radio" id="no-{id}" name="absencesByTL-{id}" value="No"  on:click={()=> absenceResponseByTL(id,"-1")} checked={accepted_by_team_leader === 'No'} class="pointer decline">No</label>	
			{/if}
		</div>
	</td>
	
	<td>{accepted_by_ceo}</td>
	
	<td>
		<div style="margin:auto">
			{#if accepted_by_team_leader === 'Yes' && id !== -1 && approvable !== "No"}
				<button
					on:click={() => absenceResponse(id, "1")}
					class="pointer accept">Accept</button
				>
				<button
					on:click={() => absenceResponse(id, "-1")}
					class="pointer decline">Decline</button
				>
			{/if}
		</div>
	</td>
</tr>


<style>
	td {
		height: 45px;
		color: #6a6a6a;
	}

	.flex-row {
		display: flex;
		justify-content: center;
	}

	.accepTl{
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.accepTl input{
		margin-right: 5px;
	}

	tr td {
		text-align: center;
	}

	button {
		margin: 0 5px;
		border: none;
		border-radius: 6px;
	}

	.accept {
		background-color: #a1ff906e;
	}

	.decline {
		background-color: #ff90906e;
	}

	.pointer {
		cursor: pointer;
	}

	.center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	button {
		margin: 0;
	}

	[data-tooltip] {
		position: relative;
		z-index: 2;
		display: block;
	}

	[data-tooltip]:before,
	[data-tooltip]:after {
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		transition: 0.2s ease-out;
		transform: translate(-50%, 5px);
	}

	[data-tooltip]:before {
		position: absolute;
		bottom: 100%;
		left: 50%;
		margin-bottom: 5px;
		padding: 7px;
		width: 100%;
		min-width: 70px;
		max-width: 250px;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		background-color: #000;
		background-color: hsla(0, 0%, 20%, 0.9);
		color: #fff;
		content: attr(data-tooltip);
		text-align: center;
		font-size: 14px;
		line-height: 1.2;
		transition: 0.2s ease-out;
	}

	[data-tooltip]:after {
		position: absolute;
		bottom: 100%;
		left: 50%;
		width: 0;
		border-top: 5px solid #000;
		border-top: 5px solid hsla(0, 0%, 20%, 0.9);
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
		content: " ";
		font-size: 0;
		line-height: 0;
	}

	[data-tooltip]:hover:before,
	[data-tooltip]:hover:after {
		visibility: visible;
		opacity: 1;
		transform: translate(-50%, 0);
	}
	[data-tooltip="false"]:hover:before,
	[data-tooltip="false"]:hover:after {
		visibility: hidden;
		opacity: 0;
	}

	.pointer {
		cursor: pointer;
	}
</style>
